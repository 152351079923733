<template>
  <div :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
    <vx-card>
      <vs-tabs alignment="fixed" v-model="activeTab">
        <div class="my-2">
          <vs-tab label="Customer"
                  icon-pack="feather"
                  :icon="errors.tabCustomer.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabCustomer.isError ? 'danger' : null">
            <TabCustomer :initData="initDataTabCustomer"/>
          </vs-tab>

          <vs-tab label="Booking & Properti"
                  icon-pack="feather"
                  :icon="errors.tabProperti.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabProperti.isError ? 'danger' : null">
            <TabProperti :initData="initDataTabProperti"/>
          </vs-tab>

          <vs-tab label="Angsuran"
                  icon-pack="feather"
                  :icon="errors.tabAngsuran.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabAngsuran.isError ? 'danger' : null">
            <TabAngsuran :initData="initDataTabAngsuran"/>
          </vs-tab>

          <vs-tab label="Signature"
                  icon-pack="feather"
                  :icon="errors.tabSignature.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabSignature.isError ? 'danger' : null">
            <TabSignature :initData="initDataTabSignature"/>
          </vs-tab>
        </div>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 3">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading || !isAnyUnsavedChanges" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import BookingRepository from '@/repositories/marketing/booking-repository'
import TabCustomer from '@/views/pages/marketing/booking/tabs/TabCustomer'
import TabProperti from '@/views/pages/marketing/booking/tabs/TabProperti'
import TabAngsuran from '@/views/pages/marketing/booking/tabs/TabAngsuran'
import TabSignature from '@/views/pages/marketing/booking/tabs/TabSignature'
import moduleBookingAdd from '@/store/modules/marketing/booking/booking-add.store'
import { convertToFormData } from '@/utilities/common/global-methods'
import { mapState } from 'vuex'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'BookingEdit',
  components: {
    TabSignature,
    TabProperti,
    TabCustomer,
    TabAngsuran
  },
  mounted () {
    this.$store.commit('marketing/bookingAdd/SET_ANY_UNSAVED_CHANGES', false)
    this.$store.commit('marketing/bookingAdd/CLEAR_ERRORS')
    this.listenKeyboardPressed()
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      isLoadingInitData: false,
      initDataTabCustomer: null,
      initDataTabProperti: null,
      initDataTabAngsuran: null,
      initDataTabSignature: null
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.marketing.bookingAdd.activeTab
      },
      set (value) {
        this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.marketing.bookingAdd.errors,
      isAnyUnsavedChanges: state => state.marketing.bookingAdd.isAnyUnsavedChanges,
      dataTabCustomer: state => state.marketing.bookingAdd.tabCustomer,
      dataTabProperti: state => state.marketing.bookingAdd.tabProperti,
      dataTabAngsuran: state => state.marketing.bookingAdd.tabAngsuran,
      dataTabSignature: state => state.marketing.bookingAdd.tabSignature
    })
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true

      const idBooking = this.$route.params.idBooking
      const params = {
        withUnitInformation: 1,
        withCustomerInformation: 1,
        withAngsurans: 1
      }
      BookingRepository.show(idBooking, params)
        .then(response => {
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    buildInitData (initData) {
      // =====TAB CUSTOMER===== //
      const initDataTabCustomer = {
        nama_pemesan: initData.nama_pemesan,
        customer: initData.customer,
        aktifitasMarketing: {
          id_proyek: initData.id_proyek,
          id_staf: initData.id_staf,
          nama_staf: initData.nama_staf,
          nama_staf_atasan: initData.nama_staf_atasan,
          id_aktifitas_marketing: initData.id_aktifitas_marketing,
          no_bukti: initData.no_bukti_aktifitas_marketing
        }
      }

      // =====TAB PROPERTI===== //
      const initDataTabProperti = {
        tgl: initData.tgl,
        no_booking: initData.no_kontrak,
        nama_staf: initData.nama_staf,
        nama_staf_atasan: initData.nama_staf_atasan,
        keterangan_booking: initData.keterangan_booking,
        id_proyek: initData.id_proyek,
        id_properti_unit: initData.id_properti_unit,
        id_staf: initData.id_staf,
        unit: initData.properti_unit
      }

      // =====TAB ANGSURAN===== //
      const initDataTabAngsuran = {
        id_properti_unit: initDataTabProperti.unit.id,
        id_cara_bayar: initData.id_cara_bayar,
        id_bank_kpr: initData.id_bank_kpr,
        id_coa_debet: initData.id_coa_debet,
        nama_coa_debet: initData.nama_coa_debet,

        harga_standar: initData.harga_standar,
        diskon_persen: initData.diskon_persen,
        diskon_jml: initData.diskon_jml,

        listBiayaUnit: initData.list_biaya
          .filter(it => it.group === 'BIAYA UNIT')
          .map(it => {
            return {
              uuid: uuid(),
              id: it.id,
              group: it.group,
              type: it.type,
              description: it.description,
              amount: it.amount,
              is_as_termin: it.is_as_termin
            }
          }),

        ppn_persen: initData.ppn_persen,
        ppn_jml: initData.ppn_jml,
        pph_nama: initData.pph_nama,
        pph_key: initData.pph_key,
        pph_persen: initData.pph_persen,
        pph_jml: initData.pph_jml,

        listBiayaTambahan: initData.list_biaya
          .filter(it => it.group === 'BIAYA TAMBAHAN')
          .map(it => {
            return {
              uuid: uuid(),
              id: it.id,
              group: it.group,
              type: it.type,
              description: it.description,
              amount: it.amount,
              is_as_termin: it.is_as_termin
            }
          }),

        dp_persen: initData.dp_persen,
        dp_jml: initData.dp_jml,
        termin_dp: initData.termin_dp,

        termin_angsuran: initData.termin_angsuran,
        angsuran_start_due: initData.angsuran_start_due,
        angsurans: initData.angsurans
      }

      // =====TAB SIGNATURE===== //
      const initDataTabSignature = {
        signatureDataUrl: initData.file_customer_signature_url
      }

      this.initDataTabCustomer = initDataTabCustomer
      setTimeout(() => {
        this.initDataTabProperti = initDataTabProperti
        this.initDataTabAngsuran = initDataTabAngsuran
        this.initDataTabSignature = initDataTabSignature
      }, 600)
    },

    save () {
      this.$store.commit('marketing/bookingAdd/CLEAR_ERRORS')
      this.isLoading = true

      const params = convertToFormData(this.buildParams())
      BookingRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.$store.commit('marketing/bookingAdd/SET_ERRORS', errors)
              this.notifyError('Data tidak valid, mohon periksa kembali.')
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id_booking: this.$route.params.idBooking,
        tabCustomer: this.dataTabCustomer,
        tabProperti: this.dataTabProperti,
        tabAngsuran: this.dataTabAngsuran,
        tabSignature: this.dataTabSignature
      }
    },

    onSuccess () {
      this.$store.commit('marketing/bookingAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'marketing.booking' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    listenKeyboardPressed () {
      window.addEventListener('keypress', e => this.onKeyboardPressed())
    },

    onKeyboardPressed: _.debounce(function () {
      this.$store.commit('marketing/bookingAdd/SET_ANY_UNSAVED_CHANGES', true)
    }, 500)
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'bookingAdd'], moduleBookingAdd)
  },
  beforeDestroy () {
    this.$store.commit('marketing/bookingAdd/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'bookingAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
